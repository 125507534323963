import {axiosDelete, axiosGet,axiosPostWithPagination,  axiosPatch, axiosPost} from '../rest'
import {assignmentGroups, myFilter, predefinedInfo, workOrder, WorkOrderList} from './model'
import {
    AddWorkOrderSuccessEvent,
    DeleteWorkOrderSuccessEvent,
    GetAllWorkOrderSuccessEvent,
    UpdateWorkOrderSuccessEvent,
    UpdateTotalContentWorkOrderEvent,
    UpdateWorkOrderPageEvent,
} from './state-event'

import {Dispatch} from 'redux'


export async function getWorkOrderById(id: string): Promise<workOrder> {
    try {
        const res = await axiosGet<workOrder>(`/api/workorder/${id}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function ticketRunningNumber(type: string): Promise<string> {
    try {
        const res = await axiosGet<any>(`/api/ticketNumber/getTicketNumber?ticketType=${type}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAssignmentUser(): Promise<any> {
    try {
        const res = await axiosGet<assignmentGroups[]>('/api/workorder/assignmentGroups')
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getAssignmentUserWithRole(role: string): Promise<any> {
    try {
        const res = await axiosGet<assignmentGroups[]>('/api/workorder/assignment/' + role)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

export async function getPredefinedInfo(incidentID: string): Promise<any> {
    try {
        const res = await axiosGet<predefinedInfo>(`/api/workorder/predefinedInfo?ticketId=${incidentID === null ? '' : incidentID}`)
        return res.data.data!!
    } catch (error) {
        console.log(error)
        throw error
    }
}

// @ts-ignore
export const getAllWorkOrders = (role: string, search?: string, filterStatus?: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosGet<WorkOrderList[]>(`/api/workorder/search?role=${role}${search === null ? '' : '&search=' + search}${filterStatus === '' ? '' : '&filterStatus=' + filterStatus}`)
            // @ts-ignore
            dispatch(GetAllWorkOrderSuccessEvent.build(response.data.data))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}
export const getAllWorkOrdersWithPagination = (page: number, pageSize: number, role?: string, search?: string, filterStatus?: string,
                                 fieldName?: string, orderBy?: string, searchFieldValue?: myFilter[]) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPostWithPagination<WorkOrderList[]>(`/api/workorder/search?role=${role}${search === null ? '' : '&search=' + search}${filterStatus === '' ? '' : '&filterStatus=' + filterStatus}&page=${page}&pageSize=${pageSize}&fieldName=${fieldName}&orderBy=${orderBy}`, searchFieldValue)
            // @ts-ignore
            dispatch(GetAllWorkOrderSuccessEvent.build(response.data.data.content || []))
            dispatch(UpdateWorkOrderPageEvent.build(response.data.data.number + 1))
            dispatch(UpdateTotalContentWorkOrderEvent.build(response.data.data.totalElements || 0))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const addWorkOrder = (slaPolicy: workOrder) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosPost<workOrder>('/api/workorder', slaPolicy)
            // dispatch(AddWorkOrderSuccessEvent.build(response.data.data!!))
            return response.data
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

// del function (parameter id)
export const deleteworkOrder = (id: string) => {
    return async (dispatch: Dispatch) => {
        try {
            const response = await axiosDelete<void>(`/api/workorder/${id}`)
            dispatch(DeleteWorkOrderSuccessEvent.build(id))
            return response.status
        } catch (err) {
            console.log(err)
            throw err
        }
    }
}

export const updateWorkOrder = (patch: workOrder, roleID: number) => {
    return async (dispatch: Dispatch) => {
        try {
            const id = patch.id!!
            delete patch.id
            const response = await axiosPatch<workOrder>(`/api/workorder/${id}/${roleID}`, patch)
            dispatch(UpdateWorkOrderSuccessEvent.build(response.data.data!!))
            return response.status
        } catch (err) {
            throw err.response
        }
    }
}

export const findWorkOrderId = async (woId: number) => {
    try {
        const res = await axiosGet<workOrder>(`/api/workorder/findWorkOrderNumber/${woId}`)
        return res.data.data!! as workOrder
    } catch (error) {
        console.log(error)
        throw error
    }
}

export const findWorkOrderIdOrLocation = async (value: string) => {
    try {
        const res = await axiosGet<workOrder[]>(`/api/workorder/searchWOVTypePM/${value}`)
        return res.data.data!! as workOrder[]
    } catch (error) {
        console.log(error)
        throw error
    }
}

// export const getPermissionUserVspace = async (roleId: string, email: string) => {
//     try {
//         const res = await axiosGet<any>(`/api/kmRole/getKmRole?roleId=${roleId}&email=${email}`)
//         return res.data.data
//     } catch (error) {
//         console.log(error)
//         throw error
//     }
// }

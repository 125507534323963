/* eslint-disable camelcase */
/* eslint-disable no-return-await */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from 'react'
import {
    AutoComplete,
    Breadcrumb,
    Button,
    Card,
    Col,
    DatePicker,
    Dropdown,
    Form,
    Icon,
    Input,
    InputNumber,
    Menu,
    message,
    Modal,
    Row,
    Select,
    Spin,
    Tag,
    TimePicker,
    Upload
} from 'antd'
import { Link, useLocation, useParams } from 'react-router-dom'
import { Space } from '../../common-components/Space'
import { FormComponentProps } from 'antd/lib/form'
import { UploadFile } from 'antd/lib/upload/interface'
import exportIcon from '../../common-file/icon-file'
import RelateView from './RelateView'
import DetailSignature from './stepAndSignature/DetailSignature'
import { StoreState } from '../../store'
import { connect } from 'react-redux'
import {
    addWorkOrder,
    getAssignmentUser,
    getAssignmentUserWithRole,
    getPredefinedInfo,
    getWorkOrderById,
    ticketRunningNumber,
    updateWorkOrder
} from './service'
import { assignees, assignmentGroups, workOrder } from './model'
import moment from 'moment'
import FSS from '../../file-server-storage'
import { UploadLink } from '../../file-server-storage/model'
import { FileUpload } from '../../common-model'
import { getAllJob, Job, updateJobStatus } from '../Relate/Job'
import { addJob } from '../Relate/Job/'
import { Charging } from '../Relate/Charging/Model'
import { addCharging } from '../Relate/Charging/service'
import { addAssetWithWO, Asset, AssetForWO, getAssetForWOByWOId } from '../Relate/Asset'
import { decryptDataVspace, nullOrEmptyMap } from '../../common-misc'
import { getAssetFilter, searchFetch, getEditAssetFieldOption } from '../asset-management'

interface param {

}

const mapStateToProps = (state: StoreState) => {
    return {
        workOrder: state.workOrder,
        job: state.job,
        chargingGroup: state.chargingGroup,
        asset: state.asset,
        filterAsset: state.filterAsset
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    addWorkOrder: (wo: workOrder) => Promise<any>
    updateWorkOrder: (wo: workOrder, roleID: number) => Promise<number>
    addJob: (job: Job[]) => Promise<number>
    addCharging: (Charging: Charging[]) => Promise<number>
    // getAssetFilter: () => Promise<number>
    EditJob: (job: Job[]) => Promise<number>
    getAllJob: (woId?: string) => Promise<Job[] | undefined>
    getEditAssetFieldOption: () => Promise<number>
}

type Props = param & FormComponentProps & DispatchProps & StateProps
const { Option } = Select
const { TextArea } = Input
const serviceCenter = process.env.REACT_APP_BASE_URL_ROLE_SERVICE_CENTER!!
const serviceAPPROVAL = process.env.REACT_APP_BASE_URL_ROLE_APPROVAL!!
const leadServiceCenter = process.env.REACT_APP_BASE_URL_ROLE_LEAD_SERVICE_CENTER!!
const fileSize = Number(process.env.REACT_APP_FILE_SIZE_UPLOAD)

const WoForm: React.FC<Props> = (props: Props) => {
    const { getFieldDecorator, getFieldValue, setFieldsValue, resetFields } = props.form
    const [priority, SetPriority] = useState<string>('Low')
    const [fileList, setFileList] = useState<UploadFile[]>([])
    const [previewVisible, setPreviewVisible] = useState<boolean>()
    const [previewImage, setPreviewImage] = useState<string>()
    const [status, setStatus] = useState<string>('New')
    const [visible, setVisible] = useState<boolean>(false)
    const [ticketNumber, setTicketNumber] = useState<string>('')
    const [User, setUser] = useState<assignmentGroups[]>([])
    const [engineer, setEngineer] = useState<assignmentGroups[]>([])
    const [engineerName, setEngineerName] = useState<assignees[]>([])
    const [AssignedTo, setAssignedTo] = useState<assignees[]>([])
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [vSpaceName, setVspaceName] = useState<string>('')
    const [vSpaceRole, setVspaceRole] = useState<number>(0)
    const [vSpaceIncidentNo, setVspaceIncidentNo] = useState<string>('')
    const [isRedirect, setIsRedirect] = useState<boolean>(false)
    const [initWO, setInitWO] = useState<workOrder>()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [dataJob, setDataJob] = useState<Job[]>([])
    const [dataCharging, setDataCharging] = useState<Charging[]>([])
    const [dataAsset, setDataAsset] = useState<Asset[]>([])
    const [isRequireAssignGroup, setIsRequireAssignGroup] = useState<boolean>(false)
    const [isRequireAssignee, setIsRequireAssignee] = useState<boolean>(false)
    const [dataRelateAsset, setDataRelateAsset] = useState<AssetForWO[]>([])
    const [dataVspace, setDataVspace] = useState<any>(undefined)
    const [woId, setWoId] = useState<string>('')
    const [filterLocation, setFilterLocation] = useState<string[]>(props.filterAsset.location?.slice(0, 20) || [])
    const [assignedValue, setAssignedValue] = useState<string[]>()

    // const [, updateState] = useState()
    // const forceUpdate = useCallback(() => updateState({}), [])
    const dataStatus: Array<string> = ['New', 'Assigned', 'InProgress', 'Completed', 'Closed', 'Cancelled']
    const dataStatusForCreate: Array<string> = ['New', 'Assigned', 'InProgress']
    const location = useLocation()
    const { id } = useParams()
    const params = new URLSearchParams(window.location.search)
    let incidentNo = params.get('incidentNo') || ''
    let email: string = ''
    let token: string = ''
    let roleID: string = ''
    const dataVspaceInfo = decryptDataVspace()
    if (typeof dataVspaceInfo === 'object') {
        email = dataVspaceInfo.email
        token = dataVspaceInfo.token
        roleID = dataVspaceInfo.roleId.toString()
    }

    const menu = (
        <Menu>
            <Menu.Item key="1" onClick={() => SetPriority('Low')}>Low</Menu.Item>
            <Menu.Item key="2" onClick={() => SetPriority('Medium')}>Medium</Menu.Item>
            <Menu.Item key="3" onClick={() => SetPriority('High')}>High</Menu.Item>
            <Menu.Item key="4" onClick={() => SetPriority('Critical')}>Critical</Menu.Item>
        </Menu>
    )

    const loadFilter = async () => {
        if (Object.keys(props.filterAsset).length === 0) {
            props.getEditAssetFieldOption().then(() => {
                setFilterLocation(props.filterAsset.location?.slice(0, 20) || [])
            })
        }
    }
    const [assignedToValue, setAssignedToValue] = useState<string>('')

    useEffect(() => {
        loadFilter()
        setVspaceRole(Number(roleID))
        fetchAssignmentUser()
        fetchAssignmentUserEngineer(roleID)
        if (!id) {
            fetchTicketNumber()
            setVspaceIncidentNo(incidentNo)
            PrepareDataVspace()
            setVspaceName(email)
        } else {
            const idWO = id || ''
            setVspaceName(email)
            setIsEdit(true)
            fetchDataAsset(idWO)
            getWorkOrderById(idWO).then((res) => {
                setVspaceIncidentNo(res.incidentNo!!)
                setInitWO(res)
                SetPriority(res.priority)
                setStatus(res.status)
                PrepareDataVspace(res.incidentNo!!)
                const tempDate = res.appointmentDate!!.split(' ')
                setFieldsValue({
                    Type: res.type,
                    EngineerOnSite: res.engineerGroup,
                    EngineerName: res.engineerName,
                    topic: res.topic,
                    description: res.description,
                    AssignmentGroup: res.supportTeam,
                    AssignedTo: res.assignee,
                    AppointmentDate: moment(tempDate[0]),
                    Time: moment(tempDate[1].substring(0, 8), 'HH:mm:ss'),
                    Status: res.status,
                    day: res.estimateDay,
                    hour: res.estimateHour,
                    minute: res.estimateMinute,
                    Name: res.contactUserName,
                    Email: res.contactUserEmail,
                    Phone: res.contactUserPhone,
                    Location: res.contactUserLocation
                })
                setFileList(convertFileListFormat(JSON.parse(res?.attachFile!!) as UploadFile[]))
                setAssignedValue([res.supportTeam!!, res.assignee!!])
            }).catch((err) => {
                message.error(`You have unSuccessfully get the data. ${err}`)
            })
        }
    }, [])

    useEffect(() => {
        setFieldsValue({ AssignmentGroup: assignedValue ? assignedValue[0] : '', AssignedTo: assignedValue ? assignedValue[1] : '' })
    }, [assignedValue])

    const initEngineer = () => {
        User.map((user) => {
            user.assignees.forEach(assignee => {
                if (assignee.email.toString().toLowerCase() === vSpaceName.toString().toLowerCase()) {
                    setFieldsValue({
                        EngineerOnSite: user.name,
                        EngineerName: assignee.email
                    })
                }
            })
        })
    }

    const PrepareDataVspace = (data?: string) => {
        if (data) {
            incidentNo = data
        }
        setVspaceRole(Number(roleID))

        const convertData = {
            token: token,
            email: email,
            ticket_sid: incidentNo
        }
        const formData = new URLSearchParams()

        for (const key in convertData) {
            formData.append(key, convertData[key])
        }
        fetchDataVspace(incidentNo)
    }

    const fetchDataVspace = (data) => {
        getPredefinedInfo(data).then((res) => {
            if (!location.pathname.includes('/WoFormEdit')) {
                setFieldsValue({
                    Type: res[0].ticketType,
                    EngineerOnSite: res[0].roleName || '-',
                    EngineerName: res[0].owner || '-',
                    topic: res[0].subject || '-',
                    description: res[0].description || '-',
                    Name: res[0].contactUserName || '-',
                    Email: res[0].contactUserEmail || '-',
                    Phone: res[0].contactUserPhone || '-',
                    Location: res[0].contactUserLocation || '-'
                })
            }
            setDataVspace(res[0])
        }).catch((err) => {
            console.log(err)
        })
    }

    useEffect(() => {
        if (!isEdit && !vSpaceIncidentNo) {
            initEngineer()
        }
    }, [User])

    useEffect(() => {
        if (!isEdit) {
            if (getFieldValue('AssignmentGroup')) {
                setStatus('Assigned')
                props.form.setFieldsValue({
                    Status: 'Assigned'
                })
                if (User) {
                    (User || []).map((data) => {
                        if (data.name === getFieldValue('AssignmentGroup')) {
                            setAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                        }
                    })
                }
            }
            if (getFieldValue('AssignmentGroup') && getFieldValue('AssignedTo')) {
                setStatus('InProgress')
                props.form.setFieldsValue({
                    Status: 'InProgress'
                })
            }
        } else {
            if (dataStatusForCreate.includes(initWO?.status!!)) {
                if (getFieldValue('AssignmentGroup')) {
                    if (initWO?.status !== 'InProgress') {
                        setStatus('Assigned')
                        props.form.setFieldsValue({
                            Status: 'Assigned'
                        })
                    }
                    if (User) {
                        (User || []).map((data) => {
                            if (data.name === getFieldValue('AssignmentGroup')) {
                                setAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                            }
                        })
                    }
                }
                if (getFieldValue('AssignmentGroup') && getFieldValue('AssignedTo')) {
                    setStatus('InProgress')
                    props.form.setFieldsValue({
                        Status: 'InProgress'
                    })
                }
            } else {
                if (User) {
                    (User || []).map((data) => {
                        if (data.name === getFieldValue('AssignmentGroup')) {
                            setAssignedTo(data.assignees.filter(it => it.name?.length !== 0))
                        }
                    })
                }
            }
        }
    }, [getFieldValue('AssignmentGroup'), getFieldValue('AssignedTo'), User])

    useEffect(() => {
        if (engineer) {
            (engineer || []).map((data) => {
                if (data.name === getFieldValue('EngineerOnSite')) {
                    setEngineerName(data.assignees.filter(it => it.name?.length > 0))
                }
            })
        }
    }, [getFieldValue('EngineerOnSite'), engineer])

    useEffect(() => {
        checkCurrentStatusToSetRequireField()
    }, [status])

    const fetchTicketNumber = () => {
        if (!isEdit) {
            ticketRunningNumber('WorkOrder').then((res) => {
                setTicketNumber(res)
            }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
        }
    }

    const fetchAssignmentUserEngineer = (role: string) => {
        getAssignmentUserWithRole(role).then((res) => {
            setEngineer(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const fetchAssignmentUser = () => {
        getAssignmentUser().then((res) => {
            setUser(res)
        }).catch((err) => message.error(`Failed loading initial TicketNumber data. ${err}`))
    }

    const fetchDataAsset = async (id) => {
        const res = await getAssetForWOByWOId(id)
        if (res) {
            setDataRelateAsset(res)
        }
    }

    const onOKAndCancel = () => {
        setVisible(false)
    }

    const handleSubmit = (e: any): void => {
        e.preventDefault()
        document.getElementById("submit-button")?.blur()
        props.form.validateFieldsAndScroll(async (_err: any, values: any) => {
            if (!_err) {
                setIsLoading(true)
                const formatDate = moment(values.AppointmentDate).format('YYYY-MM-DD')
                const formatTime = moment(values.Time).format('HH:mm')
                const findRoleIdEngineer = (engineer || []).filter(data => data.name === values.EngineerOnSite)
                const findRoleIDUser = (User || []).filter(data => data.name === values.AssignmentGroup)
                const WO: workOrder = {
                    createdBy: vSpaceName,
                    lastModifiedBy: vSpaceName,
                    type: values.Type,
                    number: ticketNumber,
                    priority: priority,
                    engineerGroup: values.EngineerOnSite,
                    engineerRoleId: findRoleIdEngineer[0]?.roleId,
                    engineerName: values.EngineerName,
                    topic: values.topic,
                    description: values.description,
                    supportTeam: values.AssignmentGroup,
                    assignee: values.AssignedTo,
                    assigneeRoleId: findRoleIDUser[0]?.roleId,
                    appointmentDate: moment(formatDate + ' ' + formatTime).format('YYYY-MM-DD HH:mm'),
                    status: values.Status,
                    incidentNo: vSpaceIncidentNo,
                    contactUserName: values.Name,
                    contactUserEmail: values.Email,
                    contactUserPhone: values.Phone,
                    contactUserLocation: values.Location,
                    estimateDay: values.day,
                    estimateHour: values.hour,
                    estimateMinute: values.minute
                }

                if (!isEdit) {
                    if (fileList.length > 0 && fileList.length <= 5) {
                        FSS.putFile(fileList, '/wo/', (uploadLink: UploadLink[]) => {
                            WO.attachFile = JSON.stringify(uploadLink)
                            saveWO(WO)
                        })
                    } else if (fileList.length > 5) {
                        message.error('Upload File Max 5')
                        setIsLoading(false)
                    } else {
                        saveWO(WO)
                    }
                } else {
                    if (fileList.length > 0 && fileList.length <= 5) {
                        FSS.putFile(fileList, '/wo/', (uploadLink: UploadLink[]) => {
                            WO.attachFile = JSON.stringify(uploadLink)
                            EditWo(WO)
                        })
                    } else if (fileList.length > 5) {
                        message.error('Upload File Max 5')
                        setIsLoading(false)
                    } else {
                        EditWo(WO)
                    }
                }
            }
        })
    }

    const saveWO = (WO: workOrder) => {
        props.addWorkOrder(WO).catch((err) => {
            setIsLoading(false)
            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
        }).then((res) => {
            const woData = res.data as workOrder
            setWoId(res.data.id)
            const promiseAll: Promise<number | void>[] = []
            const newWoAssignee = getFieldValue('AssignedTo')
            const newWoAssignGroup = getFieldValue('AssignmentGroup')
            const isAssignToChange = initWO?.assignee !== newWoAssignee || initWO?.supportTeam !== newWoAssignGroup
            if (res?.status === 201) {
                if (dataJob) {
                    dataJob.forEach((data) => {
                        data.woId = res.data.id
                    })
                    if (dataJob?.length > 0) {
                        promiseAll.push(
                            props.addJob(dataJob).catch((err) => {
                                setIsLoading(false)
                                message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                            })
                        )
                    }
                }
                if (dataCharging) {
                    dataCharging.forEach((data) => {
                        data.woId = res.data.id
                    })
                    promiseAll.push(
                        props.addCharging(dataCharging).catch((err) => {
                            setIsLoading(false)
                            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                        })
                    )
                }
                if (dataAsset) {
                    let compareData = dataAsset
                    if (checkForDuplicates(compareData, 'id')) {
                        const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                            .map(id => {
                                return compareData.find(a => a.id === id)
                            })
                        compareData = uniqueAsset as Asset[]
                    }
                    const convertData = compareData.map((it) => {
                        return {
                            ticketId: res.data.id,
                            serialNo: it.serialNo!!,
                            assetType: it.assetType!! || null,
                            createdBy: vSpaceName,
                            lastModifiedBy: vSpaceName
                        }
                    })
                    promiseAll.push(
                        addAsset(convertData).catch((err) => {
                            setIsLoading(false)
                            message.error(`You have unSuccessfully save the data. ${err.response.message}`)
                        })
                    )
                }

//                 if (isAssignToChange) {
//                     const assignedName = AssignedTo.find((assign) => assign.email === newWoAssignee)?.name
//                     const relatedTicketNumber = dataVspace?.ticketNo
//                     promiseAll.push(
//                         sendLineNotify(`
// คุณ ${assignedName || '-'}<${newWoAssignee || '-'}>
// ได้รับ assignment
// Order ID: ${woData.number}
// Related Ticket Number : ${relatedTicketNumber || '-'}
// Assigned by: ${email}
// Topic: ${getFieldValue('topic')}
// Location: ${getFieldValue('Location')}
// https://${window.location.hostname}/WoFormEdit/${res.data.id}`, newWoAssignGroup
//                         )
//                     )
//                 }

                Promise.all(promiseAll).finally(() => {
                    setIsLoading(false)
                    message.success('You have successfully saved the data.')
                    props.getAllJob(woData.id).then((data) => {
                        const istemplate8 = data?.some((it) => it.templateNo === 8 && it.jobStatus === 'Created')
                        if (istemplate8) {
                            const promiseAll2: Promise<Job | void>[] = []
                            const ConvertDataJob = (data || []).map((it) => {
                                return { ...it, jobStatus: 'Open' }
                            })
                            ConvertDataJob.forEach((job) => {
                                promiseAll2.push(updateJobStatus(job.id?.toString()!!, job).catch((err) => {
                                    message.error(`You have unSuccessfully save the data. ${err}`)
                                }))
                            })
                            Promise.all(promiseAll2).finally(() => {
                                setIsRedirect(true)
                            })
                        } else {
                            setIsRedirect(true)
                        }
                    }).catch((err) => {
                        setIsRedirect(true)
                        console.log(err)
                    })
                })
            }
        })
    }

    const EditWo = (WO: workOrder) => {
        WO.id = initWO?.id
        WO.number = initWO?.number!!
        const newWoAssignee = getFieldValue('AssignedTo')
        const newWoAssignGroup = getFieldValue('AssignmentGroup')
        const isAssignToChange = initWO?.assignee !== newWoAssignee || initWO?.supportTeam !== newWoAssignGroup
        props.updateWorkOrder(WO, vSpaceRole).then((res) => {
            if (res === 200) {
                const promiseAll: Promise<number | void>[] = []
                if (initWO?.id) {
                    props.getAllJob(initWO?.id).then((data) => {
                        if (dataJob?.some((it) => it.templateNo === 8 && it.jobStatus === 'Created')) {
                            const filterJob = dataJob.filter((it) => it.templateNo === 8 && it.jobStatus === 'Created')
                            promiseAll.push(props.addJob(filterJob).then(() => console.log('add job success')
                            ).catch((err) => {
                                message.error(`You have unSuccessfully save the data. ${err}`)
                            }))
                        } else {
                            if (data?.length === 0) {
                                promiseAll.push(props.addJob(dataJob).then(() => console.log('add job success')
                                ).catch((err) => {
                                    message.error(`You have unSuccessfully save the data. ${err}`)
                                }))
                            }
                        }
                    }).catch((err) => console.log(err))
                }
                if (isEdit) {
                    const filterNoInsert = dataCharging.filter((data) => {
                        return data.woId === undefined
                    })
                    filterNoInsert.forEach((data) => {
                        data.woId = initWO?.id
                    })
                    promiseAll.push(props.addCharging(filterNoInsert).catch((err) => {
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    }))
                }

                if (dataRelateAsset.length === 0) {
                    let compareData = dataAsset
                    if (checkForDuplicates(compareData, 'id')) {
                        const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                            .map(id => {
                                return compareData.find(a => a.id === id)
                            })
                        compareData = uniqueAsset as Asset[]
                    }
                    const convertData = compareData.map((it) => {
                        return {
                            ticketId: initWO?.id,
                            serialNo: it.serialNo,
                            assetType: it.assetType,
                            createdBy: vSpaceName,
                            lastModifiedBy: vSpaceName
                        }
                    })
                    promiseAll.push(addAsset(convertData).catch((err) => {
                        setIsLoading(false)
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    }))
                } else {
                    // (dataAsset || []).filter(({ serialNo: id1 }) => !dataRelateAsset.some(({ serialNo: id2 }) => id2 === id1))
                    let compareData = dataAsset
                    if (checkForDuplicates(compareData, 'id')) {
                        const uniqueAsset = Array.from(new Set(compareData.map(a => a.id)))
                            .map(id => {
                                return compareData.find(a => a.id === id)
                            })
                        compareData = uniqueAsset as Asset[]
                    }
                    compareData = compareData.filter(res => res.ticketRelatedAssetId === undefined)
                    const convertData = compareData.map((it) => {
                        return {
                            ticketId: initWO?.id,
                            serialNo: it.serialNo,
                            assetType: it.assetType,
                            createdBy: vSpaceName,
                            lastModifiedBy: vSpaceName
                        }
                    })
                    promiseAll.push(addAsset(convertData).catch((err) => {
                        setIsLoading(false)
                        message.error(`You have unSuccessfully save the data. ${err}`)
                    }))
                }

//                 if (isAssignToChange) {
//                     const assignedName = AssignedTo.find((assign) => assign.email === newWoAssignee)?.name
//                     const relatedTicketNumber = dataVspace?.ticketNo
//                     promiseAll.push(
//                         sendLineNotify(`
// คุณ ${assignedName || '-'}<${newWoAssignee || '-'}>
// ได้รับ assignment
// Order ID: ${initWO?.number}
// Related Ticket Number : ${relatedTicketNumber || '-'}
// Assigned by: ${email}
// Topic: ${getFieldValue('topic')}
// Location: ${getFieldValue('Location')}
// ${window.location.href}`, newWoAssignGroup
//                         )
//                     )
//                 }

                Promise.all(promiseAll).finally(() => {
                    message.success('The update has finished successfully.')
                    // props.getAllJob(initWO?.id!!).then((data) => {
                    //     const istemplate8 = dataJob?.some((it) => it.templateNo === 8 && it.jobStatus === 'Created')
                    //     if (istemplate8) {
                    //         const promiseAll2: Promise<Job | void>[] = []
                    //         let ConvertDataJob: Job[] = []
                    //         if (data?.length !== dataJob.length) {
                    //             const jobfilter = dataJob.filter((it) => it.jobStatus === 'Created')
                    //             ConvertDataJob = (jobfilter || []).map((it) => {
                    //                 return { ...it, jobStatus: 'Open' }
                    //             })
                    //         } else {
                    //             const jobfilter = data.filter((it) => it.jobStatus === 'Created')
                    //             ConvertDataJob = (jobfilter || []).map((it) => {
                    //                 return { ...it, jobStatus: 'Open' }
                    //             })
                    //         }
                    //         ConvertDataJob.forEach((job) => {
                    //             if (job.id) {
                    //                 promiseAll2.push(updateJobStatus(job.id?.toString(), job).catch((err) => {
                    //                     message.error(`You have unSuccessfully save the data. ${err}`)
                    //                 }))
                    //             } else {
                    //                 promiseAll2.push(props.addJob([job]).then(() => console.log('add job success')
                    //                 ).catch((err) => {
                    //                     message.error(`You have unSuccessfully save the data. ${err}`)
                    //                 }))
                    //             }
                    //         })
                    //         Promise.all(promiseAll2).finally(() => {
                    //             setIsRedirect(true)
                    //         })
                    //     } else {
                    //         setIsRedirect(true)
                    //     }
                    // }).catch(() => {
                    //     setIsRedirect(true)
                    // })
                    setIsRedirect(true)
                    setIsLoading(false)
                })
            }
        }).catch((err) => {
            message.error(`You have unSuccessfully save the data. ${err}`)
            setIsLoading(false)
        })
    }

    function checkForDuplicates(array, keyName) {
        return new Set(array.map(item => item[keyName])).size !== array.length
    }

    const addAsset = async (data) => {
        const res = await addAssetWithWO(data)
        if (res.status !== 201) {
            message.error(`You have unSuccessfully save the data. ${res.data}`)
        }
    }

    const base64MimeType = (encoded: any) => {
        if (!encoded) return
        const mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/)
        if (mime && mime.length) return mime[1]
    }

    const uploadProps = {
        multiple: true,
        onRemove: (file: any) => {
            setFileList(state => {
                const index = state.indexOf(file)
                const newFileList = state.slice()
                newFileList.splice(index, 1)
                return newFileList
            })
        },
        beforeUpload: (file: any) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onloadend = function (e: any) {
                // fileList = fileList.slice(-2);
                if (file.size > fileSize) {
                    message.error('Maximum permitted size of 5 Mb')
                    return false
                }
                let fillOffFileList = fileList
                fillOffFileList.push({
                    uid: file.uid,
                    name: file.name,
                    status: 'done',
                    url: reader.result?.toString(),
                    type: base64MimeType(reader.result),
                    size: e.total,
                    thumbUrl: exportIcon(base64MimeType(reader.result))
                })
                fillOffFileList = fillOffFileList.slice(0)
                setFileList(fillOffFileList)
            }
            return false
        },
        showUploadList: { showDownloadIcon: isEdit && initWO?.status !== 'Cancelled' }
    }

    const getBase64 = (file: Blob) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
        })
    }

    const handlePreview = async (file: { url: any; preview: unknown; originFileObj: Blob }) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj)
        }
        if ((file.url as string).includes('image') || ((file.url) as string).includes('jpg') || ((file.url) as string).includes('png')) {
            setPreviewVisible(true)
        } else {
            setPreviewVisible(false)
        }
        setPreviewImage(file.url || file.preview)
    }

    const handleDownload = (file) => {
        const link = document.createElement('a')
        link.href = file.url
        link.download = file.name
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    const getJob = (job) => {
        const anchor = document.querySelector('#submit-button')
        setDataJob(job)
        anchor?.scrollIntoView({ behavior: 'smooth', block: 'center' })
        document.getElementById("submit-button")?.focus()
        setTimeout(() => document.getElementById("submit-button")?.blur(), 1000)
    }

    const getCharging = (charging) => {
        setDataCharging(charging)
    }

    const getAsset = (asset) => {
        setDataAsset(asset)
    }

    const CheckStatus = (index: number): boolean => {
        let Check: boolean = false
        const totalJobs = (props.job || []).length
        const closedJobs = (props.job || []).filter(it => it.jobStatus === 'Closed' || it.jobStatus === 'Rejected' || it.jobStatus === 'Cancelled').length
        if (index !== 6 && index !== 5) {
            if (status === 'New') {
                if (index > 1) {
                    Check = true
                }
            } else if (status === 'Assigned' && getFieldValue('AssignmentGroup')) {
                if (index < 1 || index > 2) {
                    Check = true
                }
            } else if (status === 'InProgress' && getFieldValue('AssignmentGroup') && getFieldValue('AssignedTo')) {
                if (index < 2 || index > 3) {
                    Check = true
                }
            } else if (status === 'Completed') {
                if (index < 3 || index > 4) {
                    Check = true
                }
            } else if (status === 'Closed') {
                if (index < 4) {
                    Check = true
                }
            } else if (status === 'Cancelled') {
                if (index < 5) {
                    Check = true
                }
            }
            if (index === 3 && totalJobs !== closedJobs) {
                Check = true
            }
        }
        return Check
    }

    const convertFileListFormat = (fileList: FileUpload[]) => {
        return (fileList || []).map((dataFile, index) => {
            return {
                uid: index,
                name: `${dataFile.name}`,
                status: 'done',
                url: dataFile.url,
                thumbUrl: exportIcon(dataFile.type!!),
                type: dataFile.type
            } as unknown as UploadFile
        })
    }

    const checkCurrentStatusToSetRequireField = () => {
        if (status === 'New' || status === 'Cancelled') {
            setIsRequireAssignGroup(false)
            setIsRequireAssignee(false)
        } else {
            if (status === 'Assigned') {
                setIsRequireAssignGroup(true)
                setIsRequireAssignee(false)
            } else {
                setIsRequireAssignGroup(true)
                setIsRequireAssignee(true)
            }
        }
    }

    const changeEngineer = () => {
        if (getFieldValue('EngineerOnSite')) {
            setFieldsValue({
                EngineerName: undefined
            })
        }
    }

    const ChangeAssignmentGroup = () => {
        if (getFieldValue('AssignmentGroup')) {
            setFieldsValue({
                AssignedTo: undefined
            })
        }
    }

    const checkJobStatusToCloseWO = (Value) => {
        const totalJobs = (props.job || []).length
        const closedJobs = (props.job || []).filter(it => it.jobStatus === 'Closed' || it.jobStatus === 'Rejected' || it.jobStatus === 'Cancelled').length
        if (Value === 'Closed') {
            if (totalJobs !== closedJobs) {
                message.error('Please Closed status Job before Closed status Work Order')
                setStatus('Completed')
                props.form.setFieldsValue({
                    Status: status
                })
            } else {
                if (totalJobs === 0 || totalJobs === closedJobs) {
                    setStatus(Value)
                }
            }
        } else {
            setStatus(Value)
        }
    }

    const disabledDate = (current) => {
        return current && current < moment().startOf('day')
    }

    const getDisabledHours = () => {
        const hours: any[] = []
        const currentDate = moment()
        if (!(getFieldValue('AppointmentDate') > currentDate)) {
            for (let i = 0; i < moment().hour(); i++) {
                hours.push(i)
            }
        }
        return hours
    }

    const getDisabledMinutes = (selectedHour) => {
        let minutes: any[] = []
        const currentDate = moment()
        if (!(getFieldValue('AppointmentDate') > currentDate)) {
            if (selectedHour === moment().hour()) {
                for (let i = 0; i < moment().minute(); i++) {
                    minutes.push(i)
                }
            } else {
                if (selectedHour > moment().hour()) {
                    minutes = []
                } else {
                    for (let j = 0; j < moment().minute(); j++) {
                        minutes.push(j)
                    }
                }
            }
        }
        return minutes
    }

    // const getDisabledSeconds = (selectedHour, selectedMinute) => {
    //     const Seconds:any[] = []
    //     if (selectedHour === moment().hour()) {
    //         if (selectedMinute === moment().second()) {
    //             for (var i = 0; i < moment().minute(); i++) {
    //                 Seconds.push(i)
    //             }
    //         }
    //     }
    //     return Seconds
    // }

    const checkPermissionsUser = (): boolean => {
        let check = false
        if (isEdit) {
            if (initWO?.status === 'Closed' || initWO?.status === 'Cancelled') {
                check = true
            } else {
                if (vSpaceRole !== parseInt(serviceCenter) && vSpaceRole !== parseInt(leadServiceCenter)) {
                    if (vSpaceRole !== parseInt(serviceAPPROVAL)) {
                        if (initWO?.assignee || initWO?.engineerName) {
                            if (!(initWO?.assignee?.toLowerCase() === vSpaceName.toLowerCase() && initWO.assigneeRoleId === vSpaceRole)) {
                                check = true
                            }
                            if (initWO?.engineerName?.toLowerCase() === vSpaceName.toLowerCase() && initWO.engineerRoleId === vSpaceRole) {
                                check = false
                            }
                        } else {
                            if (initWO?.assigneeRoleId !== vSpaceRole || initWO?.engineerRoleId !== vSpaceRole) {
                                check = true
                            }
                        }
                    } else {
                        check = true
                    }
                }
            }
        }
        return check
    }

    const checkPermissionEditAssignment = (): boolean => {
        let check = false
        if (isEdit) {
            if (initWO?.status === 'Closed' || initWO?.status === 'Cancelled') {
                check = true
            } else {
                if (vSpaceRole !== parseInt(serviceCenter) && vSpaceRole !== parseInt(leadServiceCenter)) {
                    if (vSpaceRole !== parseInt(serviceAPPROVAL)) {
                        if (!(vSpaceName.toLowerCase() === initWO?.assignee?.toLowerCase() && vSpaceRole === initWO.assigneeRoleId) &&
                            !(vSpaceName.toLowerCase() === initWO?.engineerName?.toLowerCase() && vSpaceRole === initWO.engineerRoleId) &&
                            !(vSpaceRole === initWO?.assigneeRoleId)) {
                            check = true
                        }
                        if (initWO?.assignee === null || initWO?.assignee === undefined) {
                            if (vSpaceRole === initWO?.assigneeRoleId) {
                                check = false
                            }
                        }
                    } else {
                        check = true
                    }
                }
            }
        }
        return check
    }

    const validateNameUniqueness = (_: any, value: any, callback) => {
        if (value) {
            if (value === '-') {
                callback()
            } else {
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(String(value).toLowerCase())) {
                    callback('Email is not a valid email')
                } else {
                    callback()
                }
                callback()
            }
        } else {
            callback()
        }
    }

    const handleCloseModal = (isClose: boolean) => {
        setVisible(isClose)
    }

    const options = filterLocation.map((item) => <Option key={item} title={item} value={item}>{item}</Option>)

    return (
        <>
            {isRedirect ? window.location.href = `/WoFormEdit/${woId || initWO?.id!!}` : null}
            {/* {isRedirect ? window.location.href = '/WoList' : null} */}
            <Breadcrumb separator=">" className={'content'}>
                <Breadcrumb.Item><Link to={'/WoList'}>Work Order List</Link></Breadcrumb.Item>
                <Breadcrumb.Item>Work Order</Breadcrumb.Item>
            </Breadcrumb>
            <Space size={20} />
            <Card>
                <Row>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <div>
                            <span>Order ID: {isEdit ? initWO?.number : ticketNumber}</span>
                            <span style={{ marginLeft: 20 }}>
                                <Dropdown overlay={menu} disabled={checkPermissionsUser()}>
                                    <Tag>{priority} <Icon type="down" /></Tag>
                                </Dropdown>
                            </span>
                        </div>
                        <div>
                            <span>
                                {dataVspace === undefined
                                    ? null
                                    : nullOrEmptyMap(dataVspace?.ticketNo)
                                        ? null
                                        : <span>Related Ticket Number : {dataVspace?.ticketNo!!}</span>
                                }
                            </span>
                        </div>
                    </Col>
                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                        <div style={{ float: 'right', marginTop: 5 }}>
                            <Button type="default" onClick={() => setVisible(true)}>View Asset Received</Button>
                        </div>
                        <Modal
                            visible={visible}
                            onOk={onOKAndCancel}
                            onCancel={onOKAndCancel}
                            footer={null}
                            width={'auto'}
                            style={{ padding: '5%', minWidth: 1024 }}
                        >
                            <DetailSignature
                                isVisible={visible}
                                JobTopic='' idJob=''
                                woId={isEdit ? initWO?.id!! : ticketNumber}
                                dataRelateAsset={dataAsset}
                                handleCloseModal={handleCloseModal}
                                isApprove={false}
                                vSpaceIncidentNo={dataVspace?.ticketNo!!}
                            />
                        </Modal>
                    </Col>
                </Row>
                <Form onSubmit={handleSubmit} layout="vertical">
                    <Row gutter={16}>
                        <Col span={12} xs={24} sm={24} md={24} lg={12} style={{ borderRight: '1px solid #E0E0E0' }}>
                            <br /><br />

                            <Row gutter={8}>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Type">
                                        {getFieldDecorator('Type', {
                                            rules: [{
                                                required: true
                                            }]
                                        })(
                                            <Select placeholder="Select an Type" disabled={checkPermissionsUser()}>
                                                <Option value="Incident">Incident</Option>
                                                <Option value="Preventive Maintenance">Preventive Maintenance</Option>
                                                <Option value="Request">Request</Option>
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} />
                            </Row>
                            <Row gutter={8}>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Engineer on site">
                                        {getFieldDecorator('EngineerOnSite', {
                                            rules: [{
                                                required: true, message: 'Engineer on site is required'
                                            }]
                                        })(
                                            <AutoComplete style={{ textAlign: 'left' }} placeholder="Engineer on site"
                                                onChange={() => changeEngineer()}
                                                disabled={checkPermissionsUser()}
                                                filterOption={(inputValue, option) =>
                                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            >
                                                {(engineer || []).map((it, index) => {
                                                    return <Option value={it.name} key={index}>{it.name}</Option>
                                                })}
                                            </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Engineer Name">
                                        {getFieldDecorator('EngineerName', {
                                            rules: [{
                                                required: true, message: 'Engineer name is required'
                                            }]
                                        })(
                                            <AutoComplete style={{ textAlign: 'left' }} placeholder="Engineer Name"
                                                disabled={checkPermissionsUser() || !getFieldValue('EngineerOnSite')}
                                                filterOption={(inputValue, option) =>
                                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                            >
                                                {engineerName.length === 0 && !getFieldValue('EngineerOnSite') && vSpaceRole !== 799 ? <Spin /> : (engineerName || []).map((it, index) => {
                                                    return <Option value={it.email} key={index}>{it.name}</Option>
                                                })}
                                            </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} style={{ marginTop: 5 }} xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item label="Topic">
                                        {getFieldDecorator('topic',
                                            {
                                                rules: [{
                                                    required: true,
                                                    whitespace: true,
                                                    message: 'Topic is required'
                                                }]
                                            })(
                                                <Input type="text" placeholder="Topic" maxLength={255}
                                                    disabled={checkPermissionsUser()} />
                                            )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24} style={{ height: 140 }} xs={24} sm={24} md={24} lg={24}>
                                    <Form.Item label="Description">
                                        {getFieldDecorator('description',
                                            {
                                                rules: [{ required: true, message: 'Description is required' }]
                                            })(
                                                <TextArea rows={4} placeholder={'Description'} maxLength={2000}
                                                    autoSize={{ minRows: 4, maxRows: 4 }}
                                                    disabled={checkPermissionsUser()} />
                                            )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Assignment group">
                                        {getFieldDecorator('AssignmentGroup', {
                                            rules: [{
                                                required: isRequireAssignGroup,
                                                whitespace: true,
                                                message: 'Assignment group is required'
                                            }]
                                        })(
                                            <Select placeholder="Select an Assignment group"
                                                onChange={() => ChangeAssignmentGroup()}
                                                filterOption={(inputValue, option) =>
                                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                disabled={checkPermissionEditAssignment()}
                                                notFoundContent={null}
                                                showSearch={true}
                                                showArrow={false}
                                            >
                                                {(User || []).map((it, index) => {
                                                    return <Option value={it.name} key={index}>{it.name}</Option>
                                                })}
                                            </Select>
                                            // <AutoComplete style={{ textAlign: 'left' }}
                                            //     placeholder="Select an Assignment group"
                                            //     onChange={() => ChangeAssignmentGroup()}
                                            //     filterOption={(inputValue, option) =>
                                            //         (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //     }
                                            //     disabled={checkPermissionEditAssignment()}
                                            // >
                                            //     {User.map((it, index) => {
                                            //         return <Option value={it.name} key={index}>{it.name}</Option>
                                            //     })}
                                            // </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                    <Form.Item label="Assigned to">
                                        {getFieldDecorator('AssignedTo', {
                                            rules: [{
                                                required: isRequireAssignee,
                                                whitespace: true,
                                                message: 'Assigned to is required'
                                            }]
                                        })(
                                            <Select placeholder="Select an Assigned to"
                                                filterOption={(inputValue, option) =>
                                                    (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                }
                                                disabled={!getFieldValue('AssignmentGroup') || checkPermissionEditAssignment()}
                                                notFoundContent={null}
                                                showSearch={true}
                                                showArrow={false}
                                            >
                                                {AssignedTo.length === 0 && getFieldValue('AssignmentGroup') ? null : (AssignedTo || []).map((it, index) => {
                                                    return <Option value={it.email} key={index}>{it.name}</Option>
                                                })}
                                            </Select>

                                            // <AutoComplete style={{ textAlign: 'left' }}
                                            //     placeholder="Select an Assigned to"
                                            //     filterOption={(inputValue, option) =>
                                            //         (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                            //     }
                                            //     disabled={!getFieldValue('AssignmentGroup') || checkPermissionEditAssignment()}
                                            // >
                                            //     {AssignedTo.length === 0 && getFieldValue('AssignmentGroup') ? <Spin /> : (AssignedTo || []).map((it, index) => {
                                            //         return <Option value={it.email} key={index}>{it.name}</Option>
                                            //     })}
                                            // </AutoComplete>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={8}>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="Appointment date">
                                        {getFieldDecorator('AppointmentDate', {
                                            // rules: [{
                                            // required: true, message: 'Appointment date is required'
                                            // }]
                                        })(
                                            <DatePicker disabledDate={disabledDate} disabled={checkPermissionsUser()}
                                                placeholder="Select an Appointment date" style={{ width: '100%' }}
                                                onOpenChange={(open) => {
                                                    if (!open) {
                                                        setFieldsValue({ Time: null })
                                                    }
                                                }}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="Time">
                                        {getFieldDecorator('Time', {
                                            // rules: [{
                                            // required: true
                                            // }]
                                        })(
                                            <TimePicker
                                                disabledHours={getDisabledHours}
                                                disabledMinutes={getDisabledMinutes}
                                                // disabledSeconds={getDisabledSeconds}
                                                placeholder="Select an Time"
                                                style={{ width: '100%' }}
                                                format="HH:mm"
                                                disabled={checkPermissionsUser()}
                                            />
                                        )}
                                    </Form.Item>
                                </Col>
                                <Col span={8} xs={24} sm={24} md={8} lg={8}>
                                    <Form.Item label="WOV Status">
                                        {getFieldDecorator('Status', {
                                            initialValue: 'New',
                                            rules: [{
                                                required: getFieldValue('AssignmentGroup') && getFieldValue('AssignedTo')
                                            }]
                                        })(
                                            <Select placeholder="Select an Status" style={{ width: '100%' }}
                                                onSelect={(e) => checkJobStatusToCloseWO(e)}
                                                disabled={checkPermissionsUser()}>
                                                {((isEdit ? dataStatus : dataStatusForCreate) || []).map((it, index) => {
                                                    return <Option value={it!!} key={index}
                                                        disabled={CheckStatus(index)}>{it}</Option>
                                                })}
                                            </Select>
                                        )}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <p>Estimate Time</p>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Days'}>
                                        {getFieldDecorator('day', {
                                            initialValue: 0,
                                            // rules: [{ required: true, message: 'Days are required' }]
                                        })(<InputNumber min={0} max={365} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Hours'}>
                                        {getFieldDecorator('hour', {
                                            initialValue: 0,
                                            // rules: [{ required: true, message: 'Hours are required' }]
                                        })(<InputNumber min={0} max={23} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                                <Col span={4} xs={8} sm={8} md={4} lg={4}>
                                    <Form.Item label={'Minutes'}>
                                        {getFieldDecorator('minute', {
                                            initialValue: 0,
                                            // rules: [{ required: true, message: 'Minutes are required' }]
                                        })(<InputNumber min={0} max={59} disabled={checkPermissionsUser()} />)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <br />
                            <div style={{ padding: 15, backgroundColor: '#F2F2F2' }}>
                                <div><h3 className='topicName'>Contact User</h3></div>
                                <Row gutter={8}>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Name">
                                            {getFieldDecorator('Name',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Name is required'
                                                    }]
                                                })(
                                                    <Input type="text" placeholder="Name" maxLength={255}
                                                        disabled={checkPermissionsUser()} />
                                                )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Email">
                                            {getFieldDecorator('Email',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Email is required'
                                                    },
                                                    { validator: validateNameUniqueness }
                                                    ]
                                                })(
                                                    <Input type="text" placeholder="Email" maxLength={255}
                                                        disabled={checkPermissionsUser()} />
                                                )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={12} xs={24} sm={24} md={24} lg={12}>
                                        <Form.Item label="Phone">
                                            {getFieldDecorator('Phone',
                                                {
                                                    rules: [{
                                                        required: true,
                                                        whitespace: true,
                                                        message: 'Phone is required'
                                                    }]
                                                })(
                                                    <Input type="text" placeholder="Phone"
                                                        disabled={checkPermissionsUser()} />
                                                )}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} />
                                    <Col span={24} xs={24} sm={24} md={24} lg={24}>
                                        {/* <Form.Item label="Location"> */}
                                        {/*    {getFieldDecorator('Location', */}
                                        {/*        { */}
                                        {/*            rules: [{ */}
                                        {/*                required: true, */}
                                        {/*                whitespace: true, */}
                                        {/*                message: 'Location is required' */}
                                        {/*            }] */}
                                        {/*        })( */}
                                        {/*        <Input type="text" placeholder="Location" maxLength={255} */}
                                        {/*            disabled={checkPermissionsUser()} /> */}
                                        {/*    )} */}
                                        {/* </Form.Item> */}

                                        <Form.Item label="Location">
                                            {getFieldDecorator('Location', {
                                                rules: [
                                                    { required: true, message: 'Location is required' },
                                                    { max: 255, message: 'message max 255 characters' }
                                                ]
                                            })(
                                                // autoCompleteData.length > 0 ? (
                                                <AutoComplete
                                                    dataSource={options}
                                                    placeholder={'Location'}
                                                    disabled={checkPermissionsUser()}
                                                    filterOption={(inputValue, option) =>
                                                        (option.props.children as string).toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                                                    }
                                                    onChange={(value) => { searchFetch(value, props.filterAsset.location || [], setFilterLocation!!) }}
                                                />
                                                // ) : (
                                                //     <Input placeholder={`${name}`} id={`${name}`} maxLength={maxInput}/>
                                                // )
                                            )}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Row>
                                <Col span={24} lg={24} md={24} sm={24} xs={24} style={{ marginBottom: '20px' }}>
                                    <Upload {...uploadProps}
                                        listType="picture"
                                        className={'upload-list-inline'}
                                        fileList={fileList}
                                        onPreview={(e: any) => handlePreview(e)}
                                        disabled={checkPermissionsUser()}
                                        onDownload={(e: any) => handleDownload(e)}
                                    >
                                        <Button disabled={fileList.length >= 5 || checkPermissionsUser()}>
                                            <Icon type="upload" /> Upload
                                        </Button>
                                    </Upload>
                                </Col>
                            </Row>
                            <Modal visible={previewVisible} footer={null} onCancel={() => setPreviewVisible(false)}>
                                <img alt={previewImage} style={{ width: '100%' }} src={previewImage} />
                            </Modal>
                        </Col>
                        <Col span={12} xs={24} sm={24} md={24} lg={12}>
                            <RelateView getJob={getJob} woId={isEdit ? initWO?.id!! : ticketNumber}
                                ticketId={isEdit ? initWO?.id!! : vSpaceIncidentNo}
                                getCharging={getCharging} getAsset={getAsset}
                                permission={checkPermissionsUser()}
                                roleId={vSpaceRole}
                                assignedTo={AssignedTo}
                                vSpaceIncidentNo={dataVspace?.ticketNo}
                                woNo={isEdit ? initWO?.number : ticketNumber}
                                setAssignedValue={setAssignedValue}
                                assignedValue={assignedValue || []}
                            />
                        </Col>
                    </Row>
                    <Form.Item style={{ float: 'right', marginTop: 10 }}>
                        <Button htmlType="button" onClick={() => {
                            Modal.confirm({
                                title: 'Are you sure?',
                                content: 'You want to leave this page ?',
                                okText: 'Yes',
                                okType: 'danger',
                                autoFocusButton: 'cancel',
                                cancelText: 'No',
                                onOk() {
                                    window.location.href = '/WoList'
                                }
                            })
                        }}
                            style={{ marginRight: 10 }}>
                            Cancel
                        </Button>
                        <Button type="primary" htmlType="submit" loading={isLoading} disabled={checkPermissionEditAssignment()} id={`submit-button`}>
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
        </>
    )
}
const MyWOForm = Form.create({ name: 'WOForm' })(WoForm)
export default connect(mapStateToProps,
    {
        addWorkOrder, updateWorkOrder: updateWorkOrder, addJob, addCharging, getAllJob, getEditAssetFieldOption
    })(MyWOForm)

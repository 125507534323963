import React, {useEffect, useState} from 'react'
import {Tabs} from 'antd'
import RelateJob from '../Relate/Job/RelateJob'
import RelateCharging from '../Relate/Charging/RelateCharging'
import RelateAsset from '../Relate/Asset/RelateAsset'
import RelateTicket from '../Relate/Ticket/RelateTicket'
import {getAssetForWOByWOId} from '../Relate/Asset'
import {assignees} from '.'
import Worklog from '../Relate/Worklog/Worklog'
import {Job} from '../Relate/Job'

interface Param {
    getJob: Function
    woId: string
    getCharging: Function
    getAsset: Function
    permission: boolean,
    ticketId: string,
    roleId: number,
    assignedTo: assignees[],
    vSpaceIncidentNo: string,
    woNo?: string
    setAssignedValue?: Function,
    assignedValue: string[]
}

type Props = Param
const { TabPane } = Tabs
const RelateView: React.FC<Props> = (props: Props) => {
    const [itemJob, setItemJob] = useState<Job[]>([])
    const [key, setKey] = useState<string>('')
    const [isAssetNotNull, setIsAssetNotNull] = useState<boolean>(true)
    useEffect(() => {
        if (props.ticketId) {
            getAssetForWOByWOId(props.ticketId).then((res) => {
                getItemAsset(res)
            })
        }
    }, [props.ticketId])

    useEffect(() => {
        if (key === '1') {
            fetchAsset()
        }
    }, [key])

    const getItemJob = (item) => {
        props.getJob(item)
        setItemJob(item)
    }

    const getItemCharging = (item) => {
        props.getCharging(item)
    }

    const getItemAsset = (item) => {
        props.getAsset(item)
    }

    const fetchAsset = async () => {
        if (props.woId) {
            const res = await getAssetForWOByWOId(props.woId)
            const data: any[] = []
            data.push(res)
            data.forEach((it, index) => {
                if (res.length !== 0 && it[index].assetType === 'asset') {
                    setIsAssetNotNull(true)
                } else {
                    setIsAssetNotNull(false)
                }
            })
        }
    }

    return (
        <>
            <br /><br />
            <Tabs type="card" onChange={(e) => setKey(e)}>
                {props.roleId === 799
                    ? <TabPane tab="Job" key="1" >
                        <RelateJob
                            getItemJob={getItemJob}
                            woId={props.woId}
                            permission={false}
                            roleId={props.roleId}
                            assignedTo={props.assignedTo}
                            vSpaceIncidentNo={props.vSpaceIncidentNo}
                            isAssetNotNull = {isAssetNotNull}
                            setAssignedValue={props?.setAssignedValue}
                            assignedValue={props.assignedValue}
                        />
                    </TabPane>
                    : <TabPane tab="Job" key="1" >
                        <RelateJob
                            getItemJob={getItemJob}
                            woId={props.woId}
                            permission={props.permission}
                            roleId={props.roleId}
                            assignedTo={props.assignedTo}
                            vSpaceIncidentNo={props.vSpaceIncidentNo}
                            isAssetNotNull = {isAssetNotNull}
                            setAssignedValue={props?.setAssignedValue}
                            assignedValue={props.assignedValue}
                        />
                    </TabPane>
                }

                <TabPane tab="Asset" key="2" disabled={props.permission}>
                    <RelateAsset getItemAsset={getItemAsset} woId={props.woId} />
                </TabPane>
                <TabPane tab="Ticket" key="3" disabled={props.permission}>
                    <RelateTicket />
                </TabPane>
                <TabPane tab="Charging" key="4" disabled={props.permission}>
                    <RelateCharging woId={props.woId} getItemCharging={getItemCharging} key={key} />
                </TabPane>
                <TabPane tab="Worklog" key="5" >
                    <Worklog woNo={props.woNo} itemJob={itemJob} key={key} permission={props.permission}/>
                </TabPane>
            </Tabs>
        </>
    )
}

export default RelateView

/* eslint-disable no-return-assign */
/* eslint-disable react/prop-types */
import React, { PropsWithChildren, useState, useEffect } from 'react'
import { Col, Icon, Layout, Menu, Row, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import MSPLogo from '../assets/images/MspMiniLogo.png'
// import Auth from '../auth/Auth'
import { UserDetails } from './UserDetails'
import { connect } from 'react-redux'
import { StoreState } from '../store'
import { loadRolesState, RoleState, checkRolesState, RoleType } from '../authorization-module/permissions'
import { getAllSupportTeam } from '../incident-management/support-team'
import { SupportTeam } from '../incident-management/incident/model'
import { FetchingState, fetchWithState } from '../common-components'
import Footer from '../common-components/DefaultFooter'
import { decryptDataVspace } from '../common-misc'
import { createDB, TestaddData, TestgetData } from '../mydbOffline'

const { Header, Sider, Content } = Layout

const mapStateToProps = (state: StoreState) => {
    return {
        userRolesAndPermissions: state.userRolesAndPermissions,
        permission: state.currentUserRole,
        supportTeam: state.supportTeams
    }
}

type StateProps = ReturnType<typeof mapStateToProps>

interface DispatchProps {
    loadRolesState: () => Promise<number>
    getAllSupportTeam: () => Promise<number>
}

interface Param {
    // auth?: Auth
    permission: RoleState
    supportTeam: SupportTeam[]
}

type Props = Param & StateProps & PropsWithChildren<Param> & DispatchProps
const activePathKm = localStorage.getItem('active_key_path')

const Sidebar: React.FC<Props> = (props: Props) => {
    const [collapsed, setCollapsed] = useState<boolean>(true)
    const [isFetchingRoleState, setFetchingRoleState] = useState<FetchingState>(FetchingState.NotStarted)
    const [isFetchingSupportTeam, setFetchingSupportTeam] = useState<FetchingState>(FetchingState.NotStarted)
    const [isBroken, setIsBroken] = useState<boolean>(false)
    const isMSPUser = localStorage.getItem('mspUser') !== null
    const isVspaceUser = localStorage.getItem('dataVspace') !== null
    const [vSpaceRole, setVspaceRole] = useState<number>()
    const [online, setOnline] = useState(window.navigator.onLine)

    const fetchData = () => {
        fetchWithState(isFetchingRoleState, setFetchingRoleState, props.loadRolesState)
        fetchWithState(isFetchingSupportTeam, setFetchingSupportTeam, props.getAllSupportTeam)
    }

    useEffect(() => {
        fetchData()
        createDB()
        if (decryptDataVspace()) {
            const dataVspace = decryptDataVspace()!!
            setVspaceRole(dataVspace.roleId)
        }
        window.addEventListener("online", () => setOnline(true))
        window.addEventListener("offline", () => setOnline(false))
    }, [])

    useEffect(() => {
        if (navigator.onLine) {
            // clearDB()
            // console.log('clear data');
            // getDaTaWorkOrderIdb()
            // console.log('get')
            // TestgetData()
        } else {
            // getDaTaWorkOrderIdb()
            // console.log('add')
            // TestaddData()
        }
        console.log(online);
    }, [navigator.onLine, online])

    let pin = '1'
    const pathname = window.location.pathname
    if (pathname.includes('/Incident')) {
        pin = '2'
    } else if (pathname.includes('/Knowledge')) {
        pin = '3'
    } else if (pathname.includes('/Setting')) {
        pin = '4'
    } else if (pathname.includes('/Approval')) {
        pin = '5'
    } else if (pathname.includes('/assetList')) {
        pin = '6'
    } else if (pathname.includes('/Preventive')) {
        pin = '7'
    } else if (pathname.includes('/WoList')) {
        pin = '8'
    }

    if (!pathname.includes('/Knowledge') || !activePathKm) { localStorage.setItem('active_key_path', ',all') }
    const toggle = () => {
        setCollapsed(!collapsed)
    }

    const buildGitInfoTooltip = () => {
        return <>
            {`${process.env.REACT_APP_ENV} connecting to backend at ${process.env.REACT_APP_BASE_URL}, code from GIT branch ${process.env.REACT_APP_GIT_BRANCH} revision ${process.env.REACT_APP_GIT_COMMIT}`}
            <a target="_blank" href="gitlog.txt">
                See gitlog details here
            </a>
        </>
    }

    return (
        <>
            <Layout>
                <Sider
                    breakpoint="md"
                    collapsedWidth={isBroken ? '0' : '70'}
                    onBreakpoint={broken => {
                        // console.log(broken)
                        setIsBroken(broken)
                    }}
                    onCollapse={(collapsed, type) => {
                        // console.log(collapsed, type)
                    }}
                    trigger={null} collapsible collapsed={collapsed}
                >
                    <div className="logo">
                        <Tooltip title={buildGitInfoTooltip()}>
                            <img src={MSPLogo} alt="logo" style={{ width: collapsed ? '55%' : '15%' }} />
                        </Tooltip>
                        <h6 style={{ color: 'white', fontSize: '12px', display: collapsed ? 'none' : '' }}>IT Service
                            Management</h6>
                    </div>
                    <Menu theme="dark" mode="inline" defaultSelectedKeys={[pin]}>
                        {isMSPUser ? <Menu.Item key="1" >
                            <SidebarMenu title="Dashboard" collapsed={collapsed} path="/dashboard" icon="appstore" />
                        </Menu.Item> : null}
                        {isMSPUser ? <Menu.Item key="2" disabled={!checkRolesState(RoleType.Incident, 'ViewIncident')}>
                            <SidebarMenu title="Incident" collapsed={collapsed} path="/IncidentList" icon="warning" />
                        </Menu.Item> : null}
                        {isVspaceUser ? <Menu.Item key="6" disabled={vSpaceRole === 799}>
                            <SidebarMenu title="AssetManagement" collapsed={collapsed} path="/assetList" icon="laptop" />
                        </Menu.Item> : null}
                        {isVspaceUser ? <Menu.Item key="7" disabled={vSpaceRole === 799}>
                            <SidebarMenu title="PreventiveMaintenance" collapsed={collapsed} path="/Preventive" icon="tool" />
                        </Menu.Item> : null}
                        {isVspaceUser ? <Menu.Item key="8">
                            <SidebarMenu title="Work Order" collapsed={collapsed} path="/WoList" icon="profile" />
                        </Menu.Item> : null}
                        {isMSPUser || isVspaceUser ? <Menu.Item key="3" disabled={!checkRolesState(RoleType.Knowledge, 'ViewKnowledge') || vSpaceRole === 799}>
                            <SidebarMenu title="Knowledge" collapsed={collapsed} path="/Knowledge" icon="bulb" />
                        </Menu.Item> : null}
                        {isMSPUser ? <Menu.Item key="4" disabled={vSpaceRole === 799}>
                            <SidebarMenu title="Setting" collapsed={collapsed} path="/Setting" icon="setting" />
                        </Menu.Item> : null}
                        {isMSPUser || isVspaceUser ? <Menu.Item key="5" disabled={!checkRolesState(RoleType.Knowledge, 'ApproveOrRejectKnowledge') || vSpaceRole === 799}>
                            <SidebarMenu title="Approval Center" collapsed={collapsed} path="/ApprovalCenter" icon="audit" />
                        </Menu.Item> : null}
                    </Menu>
                </Sider>
                <Layout id="main-content">
                    <Header style={{ background: '#fff', borderBottomStyle: 'solid', borderBottomColor: '#dddddd' }}>
                        <Row>
                            <Col span={12} style={{ zIndex: 999 }}>
                                <Icon
                                    className="trigger"
                                    type={collapsed ? 'menu' : 'menu'}
                                    onClick={toggle}
                                />
                            </Col>
                            <Col span={12} style={{ display: 'flex', justifyContent: 'flex-end', zIndex: 888 }}>
                                <UserDetails broken={isBroken} />
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            padding: 24,
                            background: '#fff',
                            minHeight: 'auto'
                        }}
                    >
                        {props.children}
                    </Content>
                    <Footer />
                </Layout>
            </Layout>
        </>
    )
}
const SidebarMenu = (props: SidebarMenuProps) => {
    return <Link to={props.path}>
        {props.collapsed ? (
            <div style={{ display: 'flex', flexFlow: 'row-reverse', paddingLeft: 10 }}>
                <Icon type={props.icon} style={{ color: '#ffffff' }} />
                <span style={{ color: '#041E42', fontWeight: 500 }}>{props.title}</span>
            </div>) : (<div>
                <Icon type={props.icon} style={{ color: '#ffffff' }} />
                <span style={{ color: '#ffffff', fontWeight: 500 }}>{props.title}</span>
            </div>)
        }
    </Link>
}

interface SidebarMenuProps {
    title: string
    path: string
    collapsed: boolean
    icon: string
}

export default connect(mapStateToProps, {
    loadRolesState,
    getAllSupportTeam
})(Sidebar)
